import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact' 
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons'

class ProductSearch extends React.Component {
  render() {
    return (
      <section className="bg-gray">
        <MDBContainer>
          <h2 className="font-alt font-w-700 text-center letter-spacing-1 mt-4 mt-xl-0 mb-5 title-xs-medium title-large">
            e-Procurement search <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
          </h2>
          <MDBRow>
            <MDBCol lg="3">
              <MDBDropdown>
                <MDBDropdownToggle caret color="primary" className="nav-link btn btn-mdb-color Ripple-parent btn-block mb-2">
                  Agencies
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  <MDBDropdownItem href="https://shop.unicomgov.com/services/organization.aspx?OrganizationId=7D7A40E1-4934-490D-9A22-EE8C45159912" target="_blank" rel="noopener">Federal civilian</MDBDropdownItem>
                  <MDBDropdownItem href="https://shop.unicomgov.com/services/organization.aspx?OrganizationId=23941744-5413-40C7-9586-BB01DCDA5E39" target="_blank" rel="noopener">Federal defense</MDBDropdownItem>
                  <MDBDropdownItem href="https://shop.unicomgov.com/services/organization.aspx?OrganizationId=64B62778-4905-44B8-BC8E-5413552D5324" target="_blank" rel="noopener">State and local</MDBDropdownItem>
                  <MDBDropdownItem href="https://shop.unicomgov.com/services/organization.aspx?OrganizationId=63821A73-E4C8-4CFC-BC3B-E0D224ADAF0E" target="_blank" rel="noopener">Other Government</MDBDropdownItem>
                  <MDBDropdownItem href="https://shop.unicomgov.com/services/organization.aspx?OrganizationId=66954143-EAD4-4FA3-8B1C-440266B24D60" target="_blank" rel="noopener">Contractors and resellers</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBCol>
            <MDBCol lg="3">
              <MDBDropdown>
                <MDBDropdownToggle caret color="primary" className="nav-link btn btn-mdb-color Ripple-parent btn-block mb-2">
                  Contracts
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  <MDBDropdownItem href="http://shop.unicomgov.com/services/contractlandingpage.aspx?Metagroup=Federal+Civilian" target="_blank" rel="noopener">Federal civilian</MDBDropdownItem>
                  <MDBDropdownItem href="http://shop.unicomgov.com/services/contractlandingpage.aspx?Metagroup=Federal+DOD" target="_blank" rel="noopener">Federal defense</MDBDropdownItem>
                  <MDBDropdownItem href="http://shop.unicomgov.com/services/contractlandingpage.aspx?Metagroup=State+and+Local" target="_blank" rel="noopener">State and local</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBCol>
            <MDBCol lg="3">
              <MDBDropdown>
                <MDBDropdownToggle caret color="primary" className="nav-link btn btn-mdb-color Ripple-parent btn-block mb-2">
                  IT assets
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  <MDBDropdownItem href="http://shop.unicomgov.com/services/contractlandingpage.aspx?Metagroup=Federal+Civilian" target="_blank" rel="noopener">Federal civilian</MDBDropdownItem>
                  <MDBDropdownItem href="http://shop.unicomgov.com/services/contractlandingpage.aspx?Metagroup=Federal+DOD" target="_blank" rel="noopener">Federal defense</MDBDropdownItem>
                  <MDBDropdownItem href="http://shop.unicomgov.com/services/contractlandingpage.aspx?Metagroup=State+and+Local" target="_blank" rel="noopener">State and local</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBCol>
            <MDBCol lg="3">
              <MDBDropdown>
                <MDBDropdownToggle caret color="primary" className="nav-link btn btn-mdb-color Ripple-parent btn-block mb-2">
                  Portal
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  <MDBDropdownItem href="https://shop.unicomgov.com/register/login.aspx" target="_blank" rel="noopener">Login</MDBDropdownItem>
                  <MDBDropdownItem href="https://shop.unicomgov.com/register/login.aspx" target="_blank" rel="noopener">Forgot login</MDBDropdownItem>
                  <MDBDropdownItem href="https://shop.unicomgov.com/register/register.aspx" target="_blank" rel="noopener">Register</MDBDropdownItem>
                  <MDBDropdownItem href="https://shop.unicomgov.com/customerservice/orderstatus.aspx" target="_blank" rel="noopener">Order status</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default ProductSearch
