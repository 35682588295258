import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { MDBAnimation } from 'mdbreact'
import image from '../images/gov-avatar.png'
import { GatsbyImage } from "gatsby-plugin-image"
import Showdown from 'showdown'

class ColumnOverlay extends React.Component {
  render() {
    const converter = new Showdown.Converter()
    return (
      <>
        <MDBRow className="overlay-box align-items-center">
          <MDBCol md="6" className="px-0 px-md-1">
            <MDBAnimation reveal type="fadeInUp">
              <div className="overlay-text">
              <GatsbyImage
                  image={this.props.image} className="img-fluid mt-2" alt="UNICOM Government avatar" />
                <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.cardtitle) }} />
                <div className="text-gray-dark" dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.cardtext) }} />
              </div>
            </MDBAnimation>
          </MDBCol>
          <MDBCol md="6">
              <div dangerouslySetInnerHTML={{ __html: this.props.body }} />
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}

export default ColumnOverlay
